/* @font-face {
  font-family: "Gotham-Ultra";
  src: url("./assets/fonts/Gotham-Ultra.ttf");
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

figure{
  margin: 0; 
  padding: 0; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, li, input{
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  margin: 0; 
  outline: none; 
} 

ul{
  width: 100%; 
  padding: 0; 
  margin: 0;
}

.dtld_btn{
  padding: 11px 20px; 
  border-radius: 100px; 
  background: #323232; 
  color: white;  
  border: none; 
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

button{
  border: none;  
  font-size: 14px;
}

form{
  width: 100%;
  display: flex;
  flex-direction: column;
}

li, li a {
  list-style-type: none;
  text-decoration: none;
  color: white; 
  box-sizing: border-box;
  outline: none; 
}

@media only screen and (max-width: 768px) {
  .dpd{
    width: 85% !important; 
    margin: 5px 0 !important; 
  }

  .dpd ul{
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .dpd{
    width: 18% !important;
    flex-shrink: 0 !important;
  }
}

.dpd{
  display: flex; 
  flex-direction: row;
  align-items: center;
  width: 15%; 
  height: 45px; 
  border: 1px solid #323232; 
  border-radius: 5px; 
  box-sizing: border-box;
  margin-right: 2%;
  position: relative;
  cursor: pointer;
  padding: 0;
}

.dpd_disabled{
  cursor: auto;
  opacity: .4;
}

.dpd ul{
  position: absolute;
  display: block;
  list-style: none;
  top: 60px; 
  left: 0; 
  background: white; 
  border-radius: 5px; 
  width: 120%;
  padding: 0px; 
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.20);
  z-index: 100;
}

.dpd li{
  display: inline-block;
  width: 100%;
  height: 50px; 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px; 
  font-weight: 500;
  text-align: left;
  padding: 5px 10px;
  color: black; 
}

.dpd_sctd, .dpd li:hover{
  cursor: pointer;
  background: rgba(50,50,50,.2);
}

.dpd input{
  flex-grow: 1;
  height: 100%; 
  width: 80px; 
  border: none; 
  outline: none; 
  background: none; 
  font-weight: 700;
  font-size: 13px;
  padding-left: 13px;
  color: #323232;
}

::placeholder { 
  color: #323232;
}

.dpd figure{
  width: 40px; 
  height: 100%;
  flex-shrink: 0;
  margin: 0; 
  padding: 6px; 
  border-left: 1px solid #323232; 
  box-sizing: border-box;
}

.dpd figure img{
  width: 100%; 
  height: 100%;
}

/* Logout */
#lgn{
  display: flex; 
  flex-direction: column;
  height: 100vh; 
  overflow: hidden;
}

#lgn_hdr{
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%; 
  box-sizing: border-box;
  padding: 70px 0 0 0; 
  flex-shrink: 0;
}

#lgn_hdr figure{
  width: 90px; 
  height: 90px; 
}

#lgn_hdr img{
  width: 100%; 
  height: 100%; 
}


#lgn_main{
  flex-grow: 1;
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#lgn_cntr{
  width: 435px;
  padding: 35px;
  box-sizing: border-box; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.10);
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  margin-top: -50px;
}

#lgn_ttl{
  font-size: 17px;;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;;
}

#lgn_btn{
  align-self: flex-start;
  margin-top: 15px; 
  padding: 11px 20px; 
  border-radius: 100px; 
  background: #EC0000; 
  color: white;  
  border: none; 
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

#lgn_rcvr{
  margin-top: 65px; 
  color: #323946; 
  font-size: 14px;
}

#lgn_rcvr span{
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px 10px;
  color: #323946; 
}

#lgn_lft{
  margin-top: 30px;
  align-self: flex-start;
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#lgn_lft p{
  margin-top: 13px;
  margin-left: 20px;
  color: #323946;
  font-weight: 500;
}

.rgst_cnt{
  position: relative;
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.rgst_cnt > .csm_in{
  margin: 0; 
}

.rgst_cnt div:first-child{
  margin-right: 15px;
}

#wr{
  display: flex;
  flex-direction: row;
  width: 100%; 
  height: 100vh; 
}

#nv{
  width: 250px; 
  height: 100vh; 
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  background: #323232; 
  padding-bottom: 50px; 
  flex-shrink: 0;
  box-sizing: border-box;
}

#nv li{
  font-weight: 500;
}

#lg{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 25px auto; 
  width: 90px; 
  height: 90px;
}


#lg img {
  width: 100%; 
  height: 100%; 
}



#prt_srch{
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%; 
  border:none; 
}

#hdr{
  display: flex;
  flex-direction: row; 
  height: 80px; 
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px; 
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
}

#hdr_so{
  margin-right: 30px; 
  cursor: pointer;
  text-decoration: underline;
  padding: 10px; 
}

#ar_g{
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
}

.ar{
  display: flex; 
  flex-direction: column;
  display: inline-block;
  width: 270px; 
  border-radius: 10px; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.10);
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
}

.ar figure{
  width: 100%; 
  height: 150px; 
  margin: 0; 
  padding: 0; 
  background: #DFE2E6; 
}

.ar figure img{
  width: 50%; 
  height: 50%;
}

.ar figure object{
  width: 100%; 
  height: 100%;
  background: #DFE2E6; 
  object-fit: contain;
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ar_d{
  padding: 15px; 
}

.ar_d p{
  font-size: 14px;
  padding: 3px 0; 
  color: #707070; 
}

.ar_d_pn{
  font-size: 15px; 
  font-weight: 500;
  color: black !important; 
}

.ar_d_r{
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
}

#ar_s{
  width: 100%; 
  display: flex; 
  flex-direction: column;
  overflow-y: scroll;
}

#ar_s_w{
  padding: 20px 40px; 
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: scroll;
}

.ar_s_t{
  font-weight: 500;
  font-size: 12px;
  color: #707070
}

.ar_s_d{
 font-weight: 500;
 font-size: 14px;
 margin-bottom: 50px 
}

#ar_s_indi{
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#ar_s_img{
  width: 350px; 
  height: 350px;
  margin-top: 5px; 
  margin-bottom: 20px; 
}

#wh_w{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wh{
  width: 550px; 
  border-radius: 10px;
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.10);
  overflow: hidden;
}

.wh_fig{
  margin: 0; 
  padding: 0; 
  width: 100%; 
  height: 300px; 
}

.wh_fig img{
  width: 100%; 
  height: 100%; 
  object-fit: cover;
}

.wh_d{
  padding: 15px; 
}

.wh_d p{
  color: #707070; 
  padding: 5px 0; 
  font-size: 14px;;
}

.wh_d_n{
  font-size: 15px !important;
  color: black !important; 
  font-weight: 500;
}

.hdr_m{
  width: 30px;
  padding: 5px; 
  cursor: pointer;
  position: relative;
}

.hdr_m img {
  width: 100%; 
}

#wh_s{
  display: flex; 
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  height: 100vh;
}

#wrh{
  padding: 20px 40px; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: scroll;
  margin-bottom: 30px;
}

#wh_s_mp{
  width: 500px; 
  height: 500px; 
  margin-bottom: 30px; 
}

#wrh_ds p{
  font-size: 14px;
  font-weight: 500;
  padding: 8px; 
}

#wrh_ds span{
  font-weight: 400 !important;
}

#wrh_tb{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.wrh_tb_r{
  display: flex; 
  flex-direction: row;
  align-items: center;
}

.wrh_tb_r p{
  padding: 15px 0 15px 15px;
}

.wrh_tb_r_nm, #emp_tb_nm_col{
  border-left: 1px solid #D8D8D8; 
  border-bottom: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8; 
  flex-grow: 1;
  height: 100%;
  display: flex; 
  flex-direction: row;
  align-items: center; 
  padding-left: 15px; 
}

#emp_tb_nm_col{
  border-left: none; 
  border-bottom: none; 
  border-right: 1px solid #D8D8D8; 
}

.wrh_tb_r_un, #emp_tb_jt_col{
  border-right: 1px solid #D8D8D8; 
  border-bottom: 1px solid #D8D8D8; 
  width: 200px; 
  text-align: center;
  padding-left: 0!important;
  border-left: none;
  max-height: 50px;
}

#emp_tb_jt_col{
  border-bottom: none; 
  border-right: none;
}

#wrh_tb_hdr{
  border-top: 1px solid #99A7BF; 
}

#wrh_tb_hdr p{
  font-weight: 500;
}

.wrh_cb{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #323232; 
  width: 45px; 
  padding: 14px 0;
  height: 100%;
  border-left: 1px solid #99A7BF; 
  border-bottom: 1px solid #99A7BF;
  box-sizing: border-box;
  display: none; 
}

.wrh_cb input{
  cursor: pointer;
  width: 20px; 
  height: 20px; 
}

#chtol{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background: white; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.20);
  position: fixed;
  height: 70px;
  bottom: 30px; 
  left: 50%;
  transform: translate(-30%, 0);
  overflow: hidden;
}

#chtol_lft{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#chtol_ne{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60px; 
  height: 100%; 
  font-size: 20px;
  font-weight: 600;
  background: #323232;
  color: white
}

#chtol_d{
  margin-left: 15px;
}

#chtol_act{
  display: flex; 
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
}

#chtol_act figure{
  width: 30px; 
  margin-bottom: 5px;
}

#chtol_act img{
  width: 100%; 
}

.chtol_act{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
}

.chtol_act p{
  font-size: 12px;
  font-weight: 500;
}

/* Popup */
#fspup{
  position: fixed;
  width: 100%; 
  height: 100vh; 
  background: rgba(0,0,0,0.40); 
  left: 0; 
  top: 0; 
  z-index: 1;
}

#cnpup{
  position: fixed;
  overflow-y: scroll;
  max-height: 600px;
  width: 400px; 
  border-radius: 10px; 
  left: 50%;  
  top: 50%;
  background: white; 
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px; 
}

#tle{
  font-size: 17px;;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;;
}

.csm_in{
  position: relative;
  margin: 15px 0; 
  width: 100%; 
  display: flex; 
  flex-direction: row;
  align-items: center;
}

.csm_in input{
  width: 100%; 
  height: 55px; 
  border: 1px solid #99A7BF; 
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto";
  font-size: 15px;
  color: #323946;
  padding-right: 10px;
}

.csm_in textarea{
  width: 100%; 
  height: 100px; 
  resize: none;
  border: 1px solid #99A7BF; 
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto";
  font-size: 15px;
  color: #323946;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.csm_in_slct{
  top: -12px !important;
}

.csm_in p{
  position: absolute;
  top: 13px; 
  left: 20px;
  font-weight: 400;
  color: #99A7BF;
  background: white;
  padding: 5px;
  transition: top .15s ease-out;
}

.csm_in figure{
  position: relative;
  cursor: pointer;
  width: 50px; 
  height: 55px; 
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 1;
}

.cnpup_rw{
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
}

.cnpup_rw_s{
  width: 25px; 
  flex-shrink: 0;
}

#csm_in_hpr{
  position: absolute;
  width: max-content;
  max-width: 400px;
  padding: 15px; 
  background: #DFE2E6; 
  color: #323946; 
  box-shadow: -2px 4px 5px 1px rgba(0,0,0,0.20);
  border-radius: 10px;
  transform: translate(-90%, 0);
  top: 50px; 
  font-size: 14px;
  font-weight: 500;
  display: none; 
}

.csm_in_hpr_sh{
  display: block !important;
}

.cnpup_btn{
  padding: 12px 25px 14px 25px; 
  margin-top: 50px; 
  align-self: flex-start;
  border-radius: 100px; 
  background: #323232; 
  color: white;  
  border: none; 
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

#cnpup_des{
  font-size: 14px;
  color: #323946; 
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px; 
}

/* Popup */

/* Delete Popup */

#act_rw{
  display: flex; 
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-top: 50px; 
}

#pry_btn{
  padding: 12px 25px 14px 25px; 
  color: white; 
  background: #FB5B5A;
  border-radius: 100px; 
  font-weight: 500;
  align-self: flex-start;
}

#sdry_btn{
  margin-left: 30px;  
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px; 
}

/* Delete Popup */

.dpz_fls{
  max-width: 100%;
}

.dpz_fls > p{
  max-width: 75%;
  overflow-x: scroll;
}

.dpz_fls{
  color: black; 
  font-family: "Roboto";
  font-size: 14px; 
  font-weight: 400;
  display: flex; 
  flex-direction: row;
  align-items: center;
  margin: 6px 0;
}

.dpz_fls figure{
  flex-shrink: 0;
}

.dpz_fls p{
  margin: 0 20px; 
}

.dpz_cls{
  width: 30px; 
  height: 30px;
  cursor: pointer;
}

.dpz_ldd{
  margin: 15px 0; 
}

#dpz_txt{
  text-align: center;
}

.container{
  max-width: 100%;
}

/* Menu */
#mnu{
  position: absolute;
  top: 45px;
  right: 0px; 
  min-width: 240px;
  background: #323232; 
  border-radius: 10px; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.40);
  padding: 10px 25px; 
}

#mnu li{
  padding: 15px 0; 
}

#mnu li:hover {
  font-weight: 500;
}

/* Menu */

#mvm{
  width: 100%; 
  padding: 15px 30px;
  box-sizing: border-box; 
  overflow-y: scroll
}

#mvn_hdr{
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.mvm_rw{
  display: flex;
  flex-direction: row;
}

.tbl_ipt{
  height: 55px; 
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
}

.tbl_ipt_s{
  width: 10% !important;
}

.tbl_ipt_m{
  width: 15% !important;

}

.tbl_ipt_l{
  width: 25% !important;
}

.tbl_ipt_emp_xs{
  width: 10%; 
}

.tbl_ipt_emp_s{
  width: 14%; 
}

.tbl_ipt_emp_m{
  width: 17%; 
}

.tbl_ipt_emp_l{
  flex-grow: 1;
}

.tbl_ipt input{
  width: 100%; 
  height: 100%;
  padding: 0 40px 0 20px; 
  border: 1px solid #99A7BF; 
  border-left: 0px;   
  outline: none;
  box-sizing: border-box;
  flex-shrink: 1;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px; 
}

.tbl_cll{
  height: 55px;
  padding: 0 40px 0 20px; 
  border: 1px solid #99A7BF; 
  border-top: 0px; 
  border-left: 0px;   
  display: flex;
  flex-direction: column;
  justify-content: center;  
  box-sizing: border-box;
}

.tbl_ipt_fr{
  border-top: 1px solid #99A7BF; 
}

.frs_civ2{
  border-left: 1px solid #99A7BF !important; 
}

.tbl_ipt > p{
  font-weight: 500;
  font-size: 14px;
  padding: 10px; 
  position: absolute;
  background: white; 
  top: -20px; 
  left: 20px; 
}

.tbl_ipt > figure{
  position: absolute;
  right: 10px; 
  top: 15px;
  z-index: 2; 
  cursor: pointer;
}



#mvn_hdr{
  width: 100%; 
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.prd_src{
  width: 300px; 
  min-height: 80px;
  max-height: 200px;
  overflow-y: scroll; 
  border-radius: 10px;
  background: #323946; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.40);
  position: absolute;
  top: 70px; 
  right: -20px;
  z-index: 5;
  padding: 6px 10px; 
}

.prd{
  display: flex; 
  flex-direction: row;
  margin: 6px 0px;
  align-items: center;
}

.prd figure{
  width: 55px; 
  height: 55px; 
  margin-right: 8px; 
}

.prd img{
  width: 100%; 
  height: 100%; 
}

.prd p{
  color: white; 
}

.prd_nm{
  font-weight: 500;
  font-size: 14px;
}

.prd_unts{
  font-size: 13px;
}

.prd_srh_ldng{
  width: 100%; 
  height: 100%; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.prd_srh_ldng figure{
  width: 35px; 
  height: 35px; 
  margin: 25px 0; 
  position: static
}

.prd_srh_ldng img{
  width: 100%; 
  height: 100%; 
}

.prd_srh_nfnd{
  width: 100%; 
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.prd_srh_nfnd p{
  color: white; 
  text-align: center;
  margin: 10px 0 0 0;
}

#io_box{
  width: 300px; 
  min-height: 80px;
  border-radius: 10px;
  background: #323946; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.40);
  position: absolute;
  top: 70px; 
  right: -20px;
  z-index: 5;
  padding: 15px 20px; 
  padding-bottom: 12px; /*Compensates the weird space on the bottom that the toggle component has*/
  box-sizing: border-box;
  display: flex; 
  flex-direction: row;
  align-items: center;
}

#cldr_pup{
  width: 380px; 
  min-height: 80px;
  border-radius: 10px;
  background: #323946; 
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.40);
  position: absolute;
  top: 70px; 
  right: -20px;
  z-index: 5;
  padding: 6px 10px; 
}

#tbl_cll_lbl{
  display: flex; 
  flex-direction: row;
}

#tbl_cll_lbl p{
  font-size: 14px;
  font-weight: 500;
  padding: 15px 20px; 
  margin: 0; 
  box-sizing: border-box;
}

.tb_slct{
  background: #020202;
}

#nv a{
  display: block;
  padding: 20px; 
  width: 100%; 
}

.lnpt{
  padding: 20px; 
  cursor: pointer;
}

/* CALENDAR */
.calendar{
  font-family: "Roboto";
  color: white; 
  font-size: 15px;
  font-weight: 500;
  padding: 15px; 
}


.calendar-month_header{
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px; 
  font-size: 19px;
  font-weight: 700;
}

.calendar-header_button{
  width: 35px; 
  height: 35px;
  background: #99A7BF; 
  color: white; 
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
}

.calendar-days_of_week{
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  margin: 35px 0 30px 0; 
  font-size: 14px;
  color: #99A7BF; 
}

.calendar-week{
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  margin: 5px 0;
}

.calendar-week button{
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.is-next_month, .is-prev_month{
  color: #99A7BF; 
}

.is-today{
  background: #99A7BF; 
  color: #323946; 
  font-weight: 700;
}

#prf{
  width: 100%; 
  height: 100vh; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#prf_hdr{
  display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%; 
  height: 80px; 
  padding: 0 40px; 
  box-sizing: border-box;
  flex-shrink: 0;
}

#prf_cnt{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#prf_pct{
  margin: 50px 0;
  width: 115px; 
  height: 115px; 
  border-radius: 200px;
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.08);
  overflow: hidden;
}

#prf_pct img{
  width: 100%; 
  height: 100%; 
}

#prf_tbl{
  width: 480px; 
  border-top: 1px solid #D8D8D8; 
  border-left: 1px solid #D8D8D8; 
  border-right: 1px solid #D8D8D8; 
}

.prf_tbl_rw{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #D8D8D8;
  height: 60px; 
  align-items: center;
}

.prf_tbl_rw p{
  padding-left: 15px; 
}

.tbl_des{
  width: 130px; 
  font-weight: 500;
  border-right: 1px solid #D8D8D8;
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%; 
}

/* POPUPS */
#popup_bg{
  width: 100%; 
  position: fixed;
  height: 100vh; 
  background: rgba(0,0,0,0.4);
  z-index: 3;  
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0; 
}

#ldng_cntr{
  margin: 0; 
  border-radius: 5px; 
  width: 65px; 
  height: 65px; 
}

#ldng_cntr img{
  width: 100%; 
  height: 100%; 
}

#popup_cntr{
  width: 330px; 
  height: 180px; 
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white; 
  border-radius: 5px; 
}

#popup_cntr figure{
  width: 35px; 
  height: 35px; 
  margin-bottom: 20px; 
}

#popup_cntr img{
  width: 100%; 
  height: 100%; 
}

#popup_cntr p{
  text-align: center;
  padding: 0 20px; 
}

/* POPUPS */
#tfc{
  width: 100%; 
  height: 100vh; 
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#tfc_hdr{
  width: 100%; 
  height: 90px; 
  display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px; 
  box-sizing: border-box;
}

#map{
  width: 97%; 
  flex-grow: 1;
  display: flex;
  margin-bottom: 1.5%;
}

#map > div{
  width: 100% !important; 
  height: 100% !important; 
}

.mkr{
  width: 15px; 
  height: 15px; 
  border-radius: 10px; 
  background: #EC0000; 
}

.mixed-chart{
  width: 85%;
  padding-top: 20px; 
}

.mn_ct{
  width: 100%; 
  height: 100vh; 
  overflow-y: scroll;
  display: flex; 
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

#emp_cntr{
  margin: 0 auto; 
  width: 88%;
  margin-bottom: 50px;
}

#emp_hdr{
  display: flex; 
  flex-direction: column;
}

#nw_emp{
  width: fit-content;
  align-self: flex-end;
  margin: 20px 0;
}

#emp_amt{
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 20px;
}

#emp_tb_hdr{
  width: 100%; 
  background: #F7F7F7; 
  margin-bottom: 20px;
  border-radius: 5px; 
  display: flex; 
  flex-direction: row;
  height: 50px; 
  align-items: center;
  border: 1px solid #D8D8D8;
}

#emp_tb_hdr p {
  color: #020202; 
  font-size: 14px;
}

#emp_pfl_img, #emp_tb_img_col{
  width: 100px; 
  height: 48px; 
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #D8D8D8; 
  border-bottom: 1px solid #D8D8D8;
}

#emp_tb_img_col{
  border-left: none !important; 
  border-bottom: none !important; 
  border-right: 1px solid#D8D8D8; 
}

#emp_pfl_img img {
  width: 40px; 
  height: 40px; 
}

.wrh_tb_frst_chld {
  border-top: 1px solid #D8D8D8 !important; 
}

